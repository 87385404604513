import React, { useEffect, useState } from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import Resume from "../../settings/resume.json";
import { FirstName } from "../../utils/getName";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "150px",
        marginBottom: "80px",
        // marginLeft: "30px",
        // marginRight: "30px",
        // Commented: looks bad on mobile (seems getting negative margin at right)
        // // marginLeft: "35vw",
        // "@media (max-width: 768px)": {
        //     marginLeft: theme.spacing(4)
        // },
    },
}));

export const Content = () => {
    const classes = useStyles();
    const [showShortStory0, setShowShortStory0] = useState(false);
    const [showShortStory1, setShowShortStory1] = useState(false);
    const [showShortStory2, setShowShortStory2] = useState(false);
    const [showShortStory3, setShowShortStory3] = useState(false);

    useEffect(() => {
        const timer0 = setTimeout(() => {
            setShowShortStory0(true);
        }, 2000); // fade in after 2 seconds
        const timer1 = setTimeout(() => {
            setShowShortStory1(true);
        }, 4000); // fade in after 2 seconds
        const timer2 = setTimeout(() => {
            setShowShortStory2(true);
        }, 8000); // fade in after 2 seconds
        const timer3 = setTimeout(() => {
            setShowShortStory3(true);
        }, 20000); // fade in after 2 seconds

        // Clean up on component unmount
        return () => {
            clearTimeout(timer0);
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        };
    }, []); // Only run once after initial render

    return (
        <Container component="main" className={`${classes.main}`} maxWidth="md">
            <Typography variant="h2" component="h1" gutterBottom>
                <TextDecrypt text={`${Resume.basics.x_title} ${FirstName}`} />
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                <TextDecrypt text={`a ${Resume.basics.job}`} />
                {/* <TextDecrypt text={`from ${Resume.basics.location.country}`} /> */}
                <TextDecrypt text={`from ${Resume.basics.location.city}`} />
            </Typography>
            <br/>
            <Typography variant="h6" component="h3" gutterBottom>
                {/* <TextDecrypt text="I love finding boxes that I can think out of" /> */}
                <p className={`fade-in ${showShortStory0 ? 'show' : ''}`} align="right">I love finding boxes that I can think out of.</p>
            </Typography>
            <br/>
            <Typography variant="h6" component="h3" gutterBottom>
                <p className={`fade-in ${showShortStory1 ? 'show' : ''}`}>{`${Resume.basics.short_story}`}.</p>
            </Typography>
            <br/>
            <Typography variant="h6" component="h3" gutterBottom className={`fade-in ${showShortStory2 ? 'show' : ''}`}>
                <p>
                    Sometimes I do consulting, trainings and presentations on
                </p>
                <ul>
                    <li>Software dev in the ChatGPT era</li>
                    <li>Software Architecture, Design patterns & Best practices</li>
                    <li>Choosing the right ML tech for you: old giants vs new hype</li>
                    <li>Cross-platform dev using Unity Engine</li>
                    <li>Publishing on Google Play, App Store, Steam, Web</li>
                    <li>Practical Agile, SCRUM & TDD: no BS (Boring Stuff) edition</li>
                    <li>DevOps & CI/CD using GitHub Actions, Docker</li>
                    <li>Leveraging Midjourney, Dalle-2, and Stable Diffusion</li>
                    <li>Google Cloud Platform</li>
                    <li>Crypto trading automation & pitfalls</li>
                    {/* <li>Project management: why anyone* can do it</li> */}
                    <li>Find gold inside your own data using ML</li>
                    <li>Turning conflict into growth</li>
                    <li>Correctness vs Efficiency: when being right is wrong</li>
                    <li>Mission vs Mansion</li>
                </ul>
            </Typography>
            <Typography variant="h6" component="h3" gutterBottom>
                <p className={`fade-in ${showShortStory3 ? 'show' : ''}`}>
                    Let's say I worked a bit more than I should've
                </p>
            </Typography>
        </Container>
    );
};
